import { ItemRef } from './itemref';

export const PRISMFEATURES: ItemRef[] = [
  {
    "id": 1,
    "title": "NETSS (MMG)",
    "image": "./assets/images/home/Transfer.jpg",
    "description": "PRISM has the ability to create extracts of case data to report to the CDC utilizing NETSS.  The Message Mapping Guide (MMG) transmissions will be available later this year."
  },
  {
    "id": 2,
    "title": "Customizable Data Collection",
    "image": "./assets/images/home/DataCollection.jpg",
    "description": "PRISM offers the availability for clients to customize data collection for specialized grants or other data collection requirements."
  },
  {
    "id": 3,
    "title": "Address Validation and Geocoding",
    "image": "./assets/images/home/address.jpg",
    "description": "PRISM integrates with Datatech SmartSoft AccuMail and Geocoding software to increase data quality by providing latitude, longitude, census tract, census block, and address standardization."
  },
  {
    "id": 4,
    "title": "ELR",
    "image": "./assets/images/home/ELR.jpg",
    "description": "PRISM can consume electronic laboratory records (ELR) after HL7 engine processing with the option of auto-generating and closing of cases."
  },
  {
    "id": 5,
    "title": "eHARS",
    "image": "./assets/images/home/HivAids.png",
    "description": "PRISM integrates with eHARS data with the option of auto-generating cases."
  },
  {
    "id": 6,
    "title": "HIV Partner Services",
    "image": "./assets/images/home/Partner.jpg",
    "description": "PRISM is capable of tracking client relationships and producing the HIV Partner Service extract for the CDC."
  },
  {
    "id": 7,
    "title": "Linkage to Care",
    "image": "./assets/images/home/Linkage.jpg",
    "description": "PRISM assists staff using workflows to track clients receiving and remaining in care."
  }
]
