import { ItemRef } from './itemref';

export const STAFF: ItemRef[] = [
  {
    "id": 1,
    "title": "Mary White",
    "image": "./assets/images/about-us/Mary.jpg",
    "description": "Owner / Software Engineer / Database Analyst"
  },
  {
    "id": 2,
    "title": "Mitchell Clark",
    "image": "./assets/images/about-us/Mitchell.png",
    "description": "Videographer / Programmer / Quality Assurance Specialist"
  }
]
