import { ItemRef } from './itemref';

export const HOMEITEMS: ItemRef[] = [
  {
    "id": 1,
    "title": "Precise",
    "image": "./assets/images/home/Precise.jpg",
    "description": "PRISM enhances targeted disease control through tailored surveillance for each individual disease."
  },
  {
    "id": 2,
    "title": "Customizable",
    "image": "./assets/images/home/Customizable.jpg",
    "description": "Internal application features and edits can be turned on or off based on customer requirements."
  },
  {
    "id": 3,
    "title": "Efficient",
    "image": "./assets/images/home/Efficient.jpg",
    "description": "PRISM enables workflows to support typical tasks performed by users and provides business intelligence through dynamic reports."
  },
  {
    "id": 4,
    "title": "Mission-Oriented",
    "image": "./assets/images/home/MissionOriented.jpg",
    "description": "Task lists allow users to manage and monitor surveillance activities based on a hierarchal organization model."
  }
]
