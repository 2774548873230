import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-top-menu',
  templateUrl: './nav-top-menu.component.html',
  styleUrls: ['./nav-top-menu.component.css']
})
export class NavTopMenuComponent implements OnInit {
  isExpanded = false;

  constructor() { }

  ngOnInit(): void {
  }

}
