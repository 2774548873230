<!--<h1 class="heading">What is PRISM?</h1>-->
<div class="main-div">
  <div fxLayout="row wrap" class="ie11container">
    <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxLayout="column" style="padding: 5px;">
      <mat-card style="text-align: center;" class="ie11matcard" fxFlex="95" fxLayout.gt-sm="95" fxLayout.gt-md="95">
        <mat-card-content>
          <div class="flex-row">
            <div class="prismlogo">
              <img class="img-responsive imglogo" alt="PRISM Logo" src="./assets/images/home/PRISMtm.png" />
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div fxLayout="row wrap" class="ie11container">
  <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxLayout="column" style="padding: 5px;">
    <mat-card style="text-align: center;" class="ie11matcard" fxFlex="95" fxLayout.gt-sm="95" fxLayout.gt-md="95">
      <mat-card-content>
        <div class="flex-row">
          <div class="prismwords">
            <h2>PRISM (Patient Reporting Investigation Surveillance Manager) is a revolutionary, award-winning disease investigation and surveillance web application custom developed by ASD with guidance from the CDC. PRISM facilitates data reporting with a centralized, scalable, and flexible enterprise data management system designed specifically for communicable disease investigation and surveillance.</h2>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div fxLayout="row wrap" class="ie11container">
  <div *ngFor="let itemref of homeitems;" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" fxLayout="column" style="padding: 5px;">
    <mat-card style="text-align: center;" class="ie11matcard" fxLayout.gt-sm="45" fxLayout.gt-md="30" fxFlex="95">
      <div fxLayout="column">
        <mat-card-header>
          <mat-card-title>
            <span class="card-title-size">{{itemref.title}} </span>
          </mat-card-title>
        </mat-card-header>
        <span style="align-content: center;"><img class="img-responsive imglogo" alt="{{itemref.title}}" src="{{itemref.image}}" /></span>
        <mat-card-content style="padding:10px;">
          <p>{{itemref.description}}</p>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
<div class="main-div">
  <div fxLayout="row wrap" class="ie11container">
    <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxLayout="column" style="padding: 5px;">
      <mat-card style="text-align: center;" class="ie11matcard" fxFlex="95" fxLayout.gt-sm="95" fxLayout.gt-md="95">
        <mat-card-content>
          <div class="flex-row">
            <div>
              <h2>PRISM is ideal for organizations looking to improve disease investigation and surveillance capabilities.  Key PRISM features include:</h2>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div fxLayout="row wrap" class="ie11container">
  <div *ngFor="let pf of prismfeatures;" fxFlex="25" fxFlex.md="33" fxFlex.sm="50" fxFlex.xs="100" fxLayout="column" style="padding: 5px;">
    <mat-card style="text-align: center;" class="ie11matcard" fxLayout.gt-sm="45" fxLayout.gt-md="30" fxFlex="95">
      <div fxLayout="column">
        <mat-card-header>
          <mat-card-title>
            <span class="card-title-size">{{pf.title}} </span>
          </mat-card-title>
        </mat-card-header>
        <span style="align-content: center;"><img class="img-responsive imglogo" alt="{{pf.title}}" src="{{pf.image}}" /></span>
        <mat-card-content style="padding:10px;">
          <p>{{pf.description}}</p>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>
<div class="main-div">
  <div fxLayout="row wrap" class="ie11container">
    <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxLayout="column" style="padding: 5px;">
      <mat-card style="text-align: center;" class="ie11matcard" fxFlex="95" fxLayout.gt-sm="95" fxLayout.gt-md="95">
        <mat-card-content>
          <div class="flex-row">
            <div>
              <h2>Effective disease surveillance is crucial to CDC reporting and to maintaining public health. PRISM supports agencies conducting disease surveillance by providing cost effective, quality tools. Our team consists of Subject Matter Experts, Software Engineers, and Quality Assurance Analysts to deliver software solutions. We have a deep understanding of STD / HIV investigation and surveillance enterprise data management systems. We cater to the unique needs of each client with the goal of delivering support, data extracts, reports, and enhancements to the PRISM system. We also provide comprehensive and intuitive training materials for all user roles in PRISM.</h2>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
