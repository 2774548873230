<mat-toolbar class="mat-elevation-z6" color="primary">
  <mat-toolbar-row>
    <span class="nav-menu-border">&nbsp;</span>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span><img class="img-responsive imglogo" alt="HDS Logo" src="assets/images/home/HDS_Boxes.png" /></span>
    &nbsp;&nbsp;&nbsp;
    <div>
      <span class="top-title">Health Data Solutions</span><br />
      <div class="button-row">
        <div class="flex-container">
          <div class="button-container">
            <a [routerLink]="['/home']" [routerLinkActive]="['active']" aria-label="Home" title="Home">
              <mat-icon color="accent">home</mat-icon>
            </a>
          </div>
          <div class="button-container">
            <a [routerLink]="['/about-us']" [routerLinkActive]="['active']" aria-label="About" title="About">
              <mat-icon color="accent">info</mat-icon>
            </a>
          </div>
          <div class="example-button-container">
            <a [routerLink]="['/contact-us']" [routerLinkActive]="['active']" aria-label="Contact" title="Contact">
              <mat-icon color="accent">contacts</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <span class="nav-spacer"></span>
    <div>
      <div>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
          <a mat-menu-item [routerLink]="['/home']" [routerLinkActive]="['active']">
            <mat-icon>home</mat-icon>
            <span>Home</span>
          </a>
          <a mat-menu-item [routerLink]="['/about-us']" [routerLinkActive]="['active']">
            <mat-icon>info</mat-icon>
            <span>About</span>
          </a>
          <a mat-menu-item [routerLink]="['/contact-us']" [routerLinkActive]="['active']">
            <mat-icon>contacts</mat-icon>
            <span>Contact</span>
          </a>
        </mat-menu>
      </div>
      <!--<div class="button-row">
      <div class="flex-container">-->
      <!--<div class="button-container">
      &nbsp;
    </div>-->
      <!--<div class="button-container">
          <a [routerLink]="['/home']" [routerLinkActive]="['active']" aria-label="Home" title="Home">
            <mat-icon color="accent">home</mat-icon>
          </a>
        </div>
        <div class="button-container">
          <a [routerLink]="['/about-us']" [routerLinkActive]="['active']" aria-label="About" title="About">
            <mat-icon color="accent">info</mat-icon>
          </a>
        </div>
        <div class="example-button-container">
          <a [routerLink]="['/contact-us']" [routerLinkActive]="['active']" aria-label="Contact" title="Contact">
            <mat-icon color="accent">contacts</mat-icon>
          </a>
        </div>
      </div>
    </div>-->
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span class="nav-menu-border">&nbsp;</span>
  </mat-toolbar-row>
</mat-toolbar>
