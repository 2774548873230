
<div class="main-div">
  <div fxLayout="row wrap" class="ie11container">
    <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxLayout="column" style="padding: 5px;">
      <mat-card style="text-align: center;" class="ie11matcard" fxFlex="95" fxLayout.gt-sm="95" fxLayout.gt-md="95">
        <mat-card-content>
          <div class="flex-row">
            <div>
              <h1>Meet our staff</h1>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div class="main-div">
  <div fxLayout="row wrap" class="ie11container">
    <div *ngFor="let itemref of staff;" fxFlex="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxLayout="column" style="padding: 75px;">
      <mat-card class="ie11matcard" fxLayout.gt-sm="45" fxLayout.gt-md="30" fxFlex="95">
        <div fxLayout="column">
          <mat-card-header color="primary">
            <mat-card-title>{{itemref.title}}</mat-card-title>
            <mat-card-subtitle>{{itemref.description}}</mat-card-subtitle>
          </mat-card-header>
          <img class="img-responsive imglogo" alt="{{itemref.title}}" src="{{itemref.image}}" />
          <mat-card-content style="padding:10px;">
            <!--<p>{{itemref.description}}</p>-->
          </mat-card-content>
        </div>
      </mat-card>
    </div>
  </div>
</div>
