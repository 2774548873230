<div class="main-div">
  <div fxLayout="row wrap" class="ie11container">
    <div fxFlex="100" fxLayout="column" style="padding: 5px;">
      <mat-card class="ie11matcard" fxLayout.gt-sm="95" fxLayout.gt-md="95" fxFlex="95">
        <div fxLayout="column">
          <h1>Contact Us</h1>
          <mat-card-header>
            <mat-card-title>Health Data Solutions, LLC.</mat-card-title>
            <mat-card-subtitle>
              <b>Email:</b> prism@hdsprism.com<br /><br />
              1225 Gateshead Circle<br />
              Tallahassee, FL 32317
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content style="padding:10px;">
          </mat-card-content>
        </div>
      </mat-card>
    </div>
  </div>
</div>
